










































































































































































import { Component, Vue, Prop, Ref } from "vue-property-decorator";

import EntityCard from "./EntityCard.vue";
import NetworkRequestParamRequestModel, {
  BasicAuthenticationRequestModel,
  BodyRequestModel,
} from "@/settings/models/networks-management/NetworkRequestParamRequestModel";
import { VueForm } from "@/shared/types/ExtendedVueType";
import NetworkRequestParamResponseModel, {
  RequestParamPlace,
} from "@/settings/models/networks-management/NetworkRequestParamResponseModel";
import ValidUtil from "@/shared/utils/ValidUtil";

@Component({
  components: {
    EntityCard,
  },
})
export default class RequestParamsTable extends Vue {
  @Prop() networkRequestId!: number;

  @Ref("requestParamForm") readonly requestParamForm!: VueForm;
  @Ref("basicAuthParamsForm") readonly basicAuthParamsForm!: VueForm;

  readonly requiredRule = [
    ValidUtil.required(this.$lang("validation.required")),
  ];
  readonly headers = [
    {
      text: this.$lang("appAdmin.networksManagement.place"),
      align: "start",
      value: "place",
    },
    {
      text: this.$lang("appAdmin.networksManagement.key"),
      align: "start",
      value: "paramKey",
    },
    {
      text: this.$lang("appAdmin.networksManagement.value"),
      align: "start",
      value: "paramValue",
    },
    {
      text: this.$lang("appAdmin.networksManagement.actions"),
      align: "end",
      value: "actions",
      sortable: false,
      width: "8%",
    },
  ];
  readonly placeItems = Object.entries(RequestParamPlace).map(
    ([key, value]) => ({
      text: this.$lang(`appAdmin.networksManagement.${key.toLowerCase()}`),
      value,
    })
  );

  requestParam: NetworkRequestParamRequestModel =
    new NetworkRequestParamRequestModel();
  basicAuthParams: BasicAuthenticationRequestModel =
    new BasicAuthenticationRequestModel();
  bodyParam: BodyRequestModel = new BodyRequestModel();
  authParamForUpdate = new NetworkRequestParamRequestModel();
  editingItemId = 0;
  requestParamFormIsValid = true;
  basicAuthParamsFormIsValid = true;
  menu = false;
  requestParamVisible = false;

  get isLoading(): boolean {
    return this.$store.state.networksManagementStore.requestParamsIsLoading;
  }

  get savingInProgress(): boolean {
    return this.$store.state.networksManagementStore
      .savingRequestParamInProgress;
  }

  get requestParams(): Array<NetworkRequestParamResponseModel> {
    return this.$store.state.networksManagementStore.requestParams.filter(
      ({ requestId }: NetworkRequestParamResponseModel) =>
        requestId === this.networkRequestId
    );
  }

  get hasBasicAuthentication(): boolean {
    return !!this.requestParams.find(
      (requestParam) =>
        requestParam.place === RequestParamPlace.HEADER &&
        requestParam.paramKey === "Authorization"
    );
  }

  get isSelectedPlaceBody(): boolean {
    return this.requestParam.place === RequestParamPlace.BODY;
  }

  get formTitle(): string {
    return this.$lang(
      `appAdmin.networksManagement.requestParams.${
        this.editingItemId ? "edit" : "new"
      }`
    );
  }

  handleChangeVisibleRequestParamForm(value: boolean) {
    if (!value) {
      this.resetLocalData();
    }

    this.requestParamVisible = value;
  }

  async handleSavingRequestParam(closeDialog: () => void) {
    if (this.requestParam.place === RequestParamPlace.BODY) {
      this.bodyParam.requestId = this.networkRequestId;

      if (this.editingItemId) {
        // TODO: add functionality when the update functionality appears on the backend
        await this.$store.dispatch("updateRequestParamBody", {
          id: this.editingItemId,
          payload: this.bodyParam,
        });
      } else {
        await this.$store.dispatch("addRequestParamBody", this.bodyParam);
      }
    } else {
      this.requestParam.requestId = this.networkRequestId;

      if (this.editingItemId) {
        await this.$store.dispatch("updateRequestParam", {
          id: this.editingItemId,
          payload: this.requestParam,
        });
      } else {
        await this.$store.dispatch("addRequestParam", this.requestParam);
      }
    }

    closeDialog();
    this.resetLocalData();
  }

  async handleSavingBasicAuthentication() {
    if (!this.basicAuthParamsForm.validate()) {
      return;
    }

    this.basicAuthParams.requestId = this.networkRequestId;

    if (this.editingItemId) {
      await this.$store.dispatch("updateRequestParam", {
        id: this.editingItemId,
        payload: this.authParamForUpdate,
      });

      this.editingItemId = 0;
      this.authParamForUpdate = new NetworkRequestParamRequestModel();
    } else {
      await this.$store.dispatch(
        "addRequestParamBasicAuthentication",
        this.basicAuthParams
      );
    }

    this.menu = false;
    this.basicAuthParamsForm.reset();
  }

  handleDeletingItem(itemId: number) {
    this.$store.dispatch("deleteRequestParam", itemId);
  }

  handleClickByTableRow(item: NetworkRequestParamResponseModel) {
    if (
      item.place === RequestParamPlace.HEADER &&
      item.paramKey === "Authorization"
    ) {
      this.menu = true;
      this.authParamForUpdate = new NetworkRequestParamRequestModel(
        item.requestId,
        item.place,
        item.paramKey,
        item.paramValue
      );
    } else if (item.place === RequestParamPlace.BODY) {
      this.requestParam = new NetworkRequestParamRequestModel(
        item.requestId,
        item.place
      );
      this.bodyParam = new BodyRequestModel(item.requestId, item.paramValue);
      this.requestParamVisible = true;
    } else {
      this.bodyParam = new BodyRequestModel();
      this.requestParam = new NetworkRequestParamRequestModel(
        item.requestId,
        item.place,
        item.paramKey,
        item.paramValue
      );
      this.requestParamVisible = true;
    }

    this.editingItemId = item.id;
  }

  resetLocalData() {
    this.editingItemId = 0;
    this.bodyParam = new BodyRequestModel();
    this.requestParam = new NetworkRequestParamRequestModel();
  }
}
