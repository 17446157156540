import { RequestParamPlace } from "./NetworkRequestParamResponseModel";

export default class NetworkRequestParamRequestModel {
  constructor(
    public requestId = 0,
    public place = RequestParamPlace.HEADER,
    public paramKey = "",
    public paramValue = ""
  ) {}
}

export class BasicAuthenticationRequestModel {
  constructor(
    public requestId = 0,
    public userName = "",
    public password = ""
  ) {}
}

export class BodyRequestModel {
  constructor(public requestId = 0, public body = "") {}
}
