



































































































import { Component, Vue, Prop } from "vue-property-decorator";

import EntityCard from "./EntityCard.vue";
import RequestSpecialParamResponseModel, {
  ParamType,
  RequestSpecialParamPlace,
} from "@/settings/models/networks-management/RequestSpecialParamResponseModel";
import RequestSpecialParamRequestModel from "@/settings/models/networks-management/RequestSpecialParamRequestModel";
import ValidUtil from "@/shared/utils/ValidUtil";

@Component({ components: { EntityCard } })
export default class RequestSpecialParamsTable extends Vue {
  @Prop() networkRequestId!: number;

  readonly requiredRule = [
    ValidUtil.required(this.$lang("validation.required")),
  ];
  readonly headers = [
    {
      text: this.$lang("appAdmin.networksManagement.place"),
      align: "start",
      value: "place",
    },
    {
      text: this.$lang("appAdmin.networksManagement.paramType"),
      align: "start",
      value: "paramType",
    },
    {
      text: this.$lang("appAdmin.networksManagement.key"),
      align: "start",
      value: "paramKey",
    },
    {
      text: this.$lang("appAdmin.networksManagement.value"),
      align: "start",
      value: "paramValue",
    },
    {
      text: this.$lang("appAdmin.networksManagement.formatter"),
      align: "start",
      value: "formatter",
    },
    {
      text: this.$lang("appAdmin.networksManagement.actions"),
      align: "end",
      value: "actions",
      sortable: false,
      width: "8%",
    },
  ];
  readonly placeItems = Object.entries(RequestSpecialParamPlace).map(
    ([key, value]) => ({
      text: this.$lang(`appAdmin.networksManagement.${key.toLowerCase()}`),
      value,
    })
  );
  visible = false;
  localValue: RequestSpecialParamRequestModel =
    new RequestSpecialParamRequestModel();
  editingItem: RequestSpecialParamResponseModel | null = null;

  get isLoading(): boolean {
    return this.$store.state.networksManagementStore
      .requestSpecialParamsIsLoading;
  }

  get savingInProgress(): boolean {
    return this.$store.state.networksManagementStore
      .savingRequestSpecialParamInProgress;
  }

  get requestSpecialParams(): Array<RequestSpecialParamResponseModel> {
    return this.$store.state.networksManagementStore.requestSpecialParams.filter(
      ({ requestId }: { requestId: number }) =>
        requestId === this.networkRequestId
    );
  }

  get paramTypeItems(): Array<Record<string, string>> {
    return Object.entries(ParamType)
      .filter(([, value]) => {
        return this.requestSpecialParams.every(
          ({ paramType }) =>
            paramType === this.editingItem?.paramType || paramType !== value
        );
      })
      .map(([key, value]) => ({
        text: key.toLowerCase(),
        value,
      }));
  }

  get formTitle(): string {
    return this.$lang(
      `appAdmin.networksManagement.requestSpecialParams.${
        this.editingItem ? "edit" : "new"
      }`
    );
  }

  handleChangeVisible(value: boolean) {
    if (!value) {
      this.resetLocalData();
    }

    this.visible = value;
  }

  async handleSavingRequestSpecialParam(closeDialog: () => void) {
    this.localValue.requestId = this.networkRequestId;

    if (this.editingItem) {
      await this.$store.dispatch("updateRequestSpecialParam", {
        id: this.editingItem.id,
        payload: this.localValue,
      });
    } else {
      await this.$store.dispatch("addRequestSpecialParam", this.localValue);
    }

    closeDialog();
    this.resetLocalData();
  }

  handleDeletingItem(itemId: number) {
    this.$store.dispatch("deleteRequestSpecialParam", itemId);
  }

  handleClickByTableRow(item: RequestSpecialParamResponseModel) {
    this.localValue = RequestSpecialParamRequestModel.ofSource(item);
    this.editingItem = item;
    this.visible = true;
  }

  resetLocalData() {
    this.localValue = new RequestSpecialParamRequestModel();
    this.editingItem = null;
  }
}
