import {
  ParamType,
  RequestSpecialParamPlace,
} from "./RequestSpecialParamResponseModel";

export default class RequestSpecialParamRequestModel {
  constructor(
    public requestId = 0,
    public place = RequestSpecialParamPlace.HEADER,
    public paramType = ParamType.START_DATE,
    public paramKey = "",
    public paramValue?: string,
    public formatter?: string
  ) {}

  static ofSource(
    model: RequestSpecialParamRequestModel
  ): RequestSpecialParamRequestModel {
    return new RequestSpecialParamRequestModel(
      model.requestId,
      model.place,
      model.paramType,
      model.paramKey,
      model.paramValue,
      model.formatter
    );
  }
}
